export enum ModuleTypesEnum {
  MOVIES = 'movies',
  ONLINE_TV = 'onlineTv',
  COLLECTION = 'collection',
  COLLECTIONS = 'collections',
}

// =================================================================

export enum ModuleSlugEnum {
  MOVIES = 'movies',
  SERIES = 'series',
  ANIMES = 'animes',
  UZBEK_FILMS = 'uzbek-films',
  CARTOONS = 'cartoons',
}

// =================================================================

export enum RatingMovieEnum {
  IMDB = 'imdb',
  KP = 'kp',
}

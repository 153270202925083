import { SVGProps } from '@/types/common.types';

export const EmptySVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="153"
      height="150"
      viewBox="0 0 153 150"
      fill="none"
      {...props}
    >
      <path
        d="M75.7391 150C117.131 150 150.685 116.421 150.685 75C150.685 33.5786 117.131 0 75.7391 0C34.3475 0 0.792969 33.5786 0.792969 75C0.792969 116.421 34.3475 150 75.7391 150Z"
        fill="#919191"
        fillOpacity="0.1"
      />
      <path
        d="M143.964 114.335V121.817H17.5034C15.5589 119.417 13.9206 117.017 12.2539 114.405H143.964V114.335Z"
        fill="#474749"
      />
      <path
        d="M148.438 56.7346H87.2266V52.8523H147.414C147.9 54.0523 148.161 55.3935 148.438 56.7346Z"
        fill="#474749"
      />
      <path
        d="M135.836 30.8293V53.3469H105.141V11.8411L116.5 12C116.987 12.3529 117.571 12.4764 117.988 12.8999C120.766 14.8764 123.474 17.0646 125.905 19.4646C127.363 20.8764 128.75 21.6971 130 23.25C131.598 25.0853 133.112 26.4529 134.5 28.5C134.987 28.8529 135.558 30.4058 135.836 30.8293Z"
        fill="#878788"
      />
      <path
        d="M136.084 30.538C135.737 30.0439 135.295 29.6997 134.878 29.2055V52.1467H106.266V12.6173L118.003 12.7497C118.003 12.7497 117.058 12.4232 116.503 11.9997C116.017 11.6467 114.808 10.9232 114.253 10.4997H104.114V54.0526H136.753V32.0291C136.545 31.6055 136.432 31.0321 136.084 30.538Z"
        fill="#474749"
      />
      <path
        d="M130.004 23.25L130.213 45.7938H110.699V19.3938H125.908C127.505 20.8056 128.684 21.6971 130.004 23.25Z"
        fill="#474749"
      />
      <path
        d="M52.3616 113.064C52.3616 113.7 51.8755 114.194 51.3199 114.405C51.2505 114.405 51.1116 114.405 51.0421 114.405H12.2221C10.2776 111.3 8.5278 108.459 7 105C8.66669 102.176 11.8054 98.6643 12.2221 95.9819C12.4304 95.0643 12.4999 94.2172 12.5693 93.2996H40.1392C40.2086 94.1466 40.417 94.9937 40.6253 95.9819C41.667 101.064 44.3754 107.417 50.0699 111.723H50.9727C51.806 111.723 52.3616 112.358 52.3616 113.064Z"
        fill="#474749"
      />
      <path
        d="M78.2683 19.1822V92.8058C78.2683 94.5705 76.8793 95.9116 75.2127 95.9116H3.74298C2.35407 91.6763 1.55849 87.159 1.00293 82.5001C0.794593 81.3707 2.85046 80.3116 2.78102 79.0411C2.71157 77.2058 2.57268 75.3705 2.57268 73.5352C2.57268 52.5705 10.9756 33.7234 24.5869 20.0999C25.9758 18.6881 27.5036 17.3469 28.962 16.0058H75.0738C76.9488 16.0058 78.2683 17.5587 78.2683 19.1822Z"
        fill="#878788"
      />
      <path
        d="M78.0579 19.1822V82.5705H1.05021C0.841877 81.4411 0.862029 80.0207 0.792583 78.7501C0.723138 76.9148 0.792601 75.3354 0.792601 73.5001C0.792601 52.5354 10.7652 33.7234 24.3765 20.0999C25.7654 18.6881 27.2932 17.3469 28.7516 16.0058H74.8634C76.7384 16.0058 78.0579 17.5587 78.0579 19.1822Z"
        fill="#474749"
      />
      <path
        d="M74.2389 20.0995V79.1113H2.84896C2.77952 77.276 2.64062 75.4407 2.64062 73.6054C2.64062 52.6407 11.0435 33.7936 24.6549 20.1701H74.2389V20.0995Z"
        fill="#474749"
      />
      <path
        d="M26.043 92.1702C27.4319 92.1702 28.543 91.0408 28.543 89.6291C28.543 88.2173 27.4319 87.0879 26.043 87.0879C24.6541 87.0879 23.543 88.2173 23.543 89.6291C23.543 91.0408 24.6541 92.1702 26.043 92.1702Z"
        fill="#474749"
      />
      <path
        d="M39.7939 97.1116V100.147H37.7105C36.53 100.147 35.4883 101.206 35.4883 102.406V106.641C35.4883 107.841 36.53 108.9 37.7105 108.9H39.7939V112.147C39.7939 113.276 40.6967 114.265 41.8773 114.265H51.1135C52.2246 114.265 53.1969 113.347 53.1969 112.147V96.9705H39.7939V97.1116ZM37.7105 107.347C37.4328 107.347 37.155 107.065 37.155 106.782V102.547C37.155 102.265 37.4328 101.982 37.7105 101.982H39.7939V107.418H37.7105V107.347Z"
        fill="#878788"
      />
      <path
        d="M46.6008 79.2524C47.4342 81.3701 47.6425 83.4877 46.3925 85.1113C45.2119 86.8054 44.3091 89.0642 45.5592 90.8289C46.7397 92.523 47.1564 93.8642 46.6703 94.7113C46.1842 95.5583 46.6703 94.6407 48.2675 92.8054C49.6564 91.323 47.1564 90.0524 47.087 88.8524C47.0175 87.723 48.4064 85.323 48.4064 83.7701C48.337 82.076 47.5731 79.8877 46.6008 79.2524Z"
        fill="#474749"
      />
      <path
        d="M43.1265 88.5C43.4735 89.5588 43.6124 90.6176 43.0571 91.4647C42.5018 92.3118 42.0159 93.4412 42.71 94.2882C43.3347 95.1353 43.4735 95.7706 43.2653 96.2647C43.0571 96.7588 43.2653 96.2647 44.0983 95.3471C44.723 94.5706 43.543 93.9353 43.4735 93.3706C43.4041 92.8059 44.0983 91.6765 44.0983 90.8294C44.0289 89.9118 43.6818 88.8529 43.1265 88.5Z"
        fill="#474749"
      />
      <path
        d="M80.2812 99.0874C81.0452 97.3933 83.6146 94.1462 86.2536 92.8051C86.6008 92.5933 87.0175 92.7345 87.1564 93.0874C87.9203 94.4286 89.7953 97.8168 89.1008 101.134C88.198 105.158 80.2812 99.0874 80.2812 99.0874Z"
        fill="#878788"
      />
      <path
        d="M77.0186 99.3697C77.0186 99.3697 73.6853 95.6991 71.5324 94.3579C70.9769 94.0756 70.3519 94.2873 70.0741 94.852C67.7129 99.3697 67.7129 104.593 68.2685 107.346C68.8935 110.311 73.8936 102.123 73.8936 102.123L77.0186 99.3697Z"
        fill="#878788"
      />
      <path
        d="M75.4899 100.571C75.4899 100.571 73.4065 98.312 72.1565 97.4649C71.8093 97.3237 71.3926 97.3943 71.2537 97.7473C69.8648 100.571 69.7954 103.747 70.2121 105.441C70.6287 107.277 73.6843 102.194 73.6843 102.194L75.4899 100.571Z"
        fill="#474749"
      />
      <path
        d="M82.4336 99.2995C82.8503 98.1701 84.517 96.0525 86.1142 95.2054C86.3225 95.1348 86.5309 95.2054 86.7392 95.4172C87.1559 96.2643 88.3365 98.4525 87.9198 100.641C87.3642 103.323 82.4336 99.2995 82.4336 99.2995Z"
        fill="#474749"
      />
      <path
        d="M147.575 142.994C140.492 139.958 141.533 130.5 142.228 121.747C142.853 121.747 143.408 121.747 144.033 121.747C144.033 119.276 144.033 116.735 144.033 114.264C144.033 114.194 140.769 114.335 140.422 114.335C138.825 114.335 137.992 113.417 136.672 112.853C136.811 112.923 136.325 116.735 136.256 117.158C133.964 127.394 129.311 130.994 133.686 135.723C135.005 137.135 135.63 140.17 132.575 141.511C129.519 142.711 127.714 138.194 126.672 136.005C124.866 132.194 126.394 126.547 128.339 114.97C128.339 114.97 106.741 114.9 100.561 114.97C100.144 116.735 96.6716 129.017 96.1854 131.558C95.4215 135.37 96.6716 138.123 96.9493 140.17C97.2271 142.358 93.616 143.841 90.9076 141.37C87.4353 138.123 91.1159 133.111 91.602 121.958C91.602 121.323 91.8104 114.476 91.3937 114.476C84.2408 114.476 77.1574 114.405 70.0045 114.547C69.1711 114.547 67.9211 114.9 67.435 114.123C63.5461 108.335 73.4073 95.276 84.3797 98.6643C86.1853 99.229 88.1298 100.429 90.0742 100.57C90.0742 100.57 91.1854 100.217 96.8105 96.0525C106.255 90.5466 118.13 90.9702 125.491 92.7349C135.422 95.1349 137.158 103.253 140.006 106.853C144.103 112.076 147.992 113.417 147.436 126.194C147.228 131.347 147.158 134.1 151.325 137.064C154.103 139.323 151.881 144.829 147.575 142.994Z"
        fill="#878788"
      />
      <path
        d="M81.3226 113.276C80.5587 113.346 79.5865 113.488 78.6837 113.488C78.4059 113.488 78.0587 113.488 77.7809 113.558C74.7253 113.629 71.8086 113.205 71.3919 110.876C71.1141 109.041 73.6836 106.711 75.0725 105.935C79.7948 103.252 84.656 107.205 85.2116 109.605C85.6282 111.652 83.4754 112.923 81.3226 113.276Z"
        fill="#474749"
      />
      <path
        d="M78.2672 110.735C79.0307 110.735 79.6554 110.453 79.6554 110.029C79.6554 109.606 79.0307 109.323 78.2672 109.323C77.5036 109.323 76.8789 109.606 76.8789 110.029C76.8789 110.453 77.5036 110.735 78.2672 110.735Z"
        fill="#878788"
      />
      <path
        d="M78.9625 110.736V113.489C78.546 113.489 77.9907 113.559 77.5742 113.559V110.806L78.9625 110.736Z"
        fill="#878788"
      />
      <path
        d="M96.8793 140.171C97.1571 142.359 93.5459 143.842 90.8375 141.371C88.1292 138.83 90.6292 130.289 91.3237 125.771C91.6709 123.371 91.6709 120.477 91.6014 118.218C92.782 119.7 95.6987 123.442 97.7821 125.418C97.1571 127.889 96.4626 130.147 96.1848 131.7C95.4209 135.3 96.5321 138.053 96.8793 140.171Z"
        fill="#878788"
      />
      <path
        d="M133.547 135.794C134.867 137.206 135.492 140.241 132.436 141.582C129.381 142.782 127.575 138.264 126.533 136.076C125.492 133.888 125.7 128.664 126.533 124.429C126.811 122.947 127.158 121.323 127.367 119.911C127.992 120.264 128.686 120.688 129.242 121.111C130.7 122.241 132.158 123.582 133.825 124.5C133.061 126.688 132.436 128.453 132.02 129.3C131.117 131.629 132.228 134.382 133.547 135.794Z"
        fill="#878788"
      />
      <path
        d="M150.764 142.147C148.681 144.124 145.139 141.865 143.195 139.606C141.25 137.347 140.833 130.288 141.528 124.147C141.597 123.088 141.736 122.171 141.597 121.324H143.472V113.841L138.25 113.25C138.722 112.659 137.569 113.321 137.5 113.25C137.569 112.968 137.708 112.924 137.778 112.641C138.264 111.159 137.5 109.676 139.167 106.5C139.653 107.276 140.069 107.7 140.556 108.124C141.458 108.971 142.431 109.606 144.097 112.006C147.361 116.524 146.875 126.053 146.736 129.794C146.667 133.676 149.236 136.076 150.834 136.712C152.222 137.418 152.778 140.171 150.764 142.147Z"
        fill="#878788"
      />
      <path
        d="M83.1269 102.406H83.0575L81.1139 102.123C80.7668 102.123 80.5586 101.77 80.5586 101.488C80.5586 101.135 80.9057 100.923 81.1833 100.923L83.1269 101.206C83.4739 101.206 83.6822 101.559 83.6822 101.841C83.6822 102.265 83.4045 102.406 83.1269 102.406Z"
        fill="#474749"
      />
      <path
        d="M73.7506 102.617H73.82L75.7636 102.335C76.1107 102.335 76.3189 101.982 76.3189 101.7C76.3189 101.347 75.9718 101.135 75.6942 101.135L73.7506 101.417C73.4036 101.417 73.1953 101.77 73.1953 102.053C73.3341 102.335 73.473 102.547 73.7506 102.617Z"
        fill="#474749"
      />
      <path
        d="M93.5211 64.2549L92.9371 60.8487L106.05 58.5258L106.634 61.9321L99.5526 74.4785L108.659 72.8654L109.243 76.2716L95.5471 78.6978L94.963 75.2915L102.045 62.7451L93.5211 64.2549Z"
        fill="#474749"
      />
      <path
        d="M83.7779 76.1365L84.3868 74.1113L92.0908 76.5042L91.482 78.5294L84.3737 83.3336L89.6868 84.9838L89.078 87.009L80.9755 84.4924L81.5843 82.4672L88.6926 77.663L83.7779 76.1365Z"
        fill="#474749"
      />
      <path d="M137.297 114.476H143.964V114.406V121.888H135.77" fill="#474749" />
    </svg>
  );
};

import type { SVGProps } from '@/types/common.types';

const SearchSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      {...props}
    >
      <path
        d="M42.5 42L30.5 30M6.5 20C6.5 21.8385 6.86212 23.659 7.56569 25.3576C8.26925 27.0561 9.30048 28.5995 10.6005 29.8995C11.9005 31.1995 13.4439 32.2307 15.1424 32.9343C16.841 33.6379 18.6615 34 20.5 34C22.3385 34 24.159 33.6379 25.8576 32.9343C27.5561 32.2307 29.0995 31.1995 30.3995 29.8995C31.6995 28.5995 32.7307 27.0561 33.4343 25.3576C34.1379 23.659 34.5 21.8385 34.5 20C34.5 18.1615 34.1379 16.341 33.4343 14.6424C32.7307 12.9439 31.6995 11.4005 30.3995 10.1005C29.0995 8.80048 27.5561 7.76925 25.8576 7.06569C24.159 6.36212 22.3385 6 20.5 6C18.6615 6 16.841 6.36212 15.1424 7.06569C13.4439 7.76925 11.9005 8.80048 10.6005 10.1005C9.30048 11.4005 8.26925 12.9439 7.56569 14.6424C6.86212 16.341 6.5 18.1615 6.5 20Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchSVG;
